<template>
	<validation-observer ref="refFormObserver">
		<b-card>
			<!-- Media -->
			<b-media class="mb-2">
				<template #aside>
					<b-avatar
						ref="previewEl"
						:src="userData.profile_image"
						:text="avatarText(userData.fullName)"
						:variant="`light-${resolveUserRoleVariant(userData.role)}`"
						size="90px"
						rounded
					/>
				</template>
				<div class="d-flex flex-wrap mt-1">
					<b-button variant="primary" @click="$refs.refInputEl.click()">
						<input
							ref="refInputEl"
							type="file"
							class="d-none"
							@input="uploadImage"
						/>
						<span class="d-none d-sm-inline">Upload</span>
						<feather-icon icon="EditIcon" class="d-inline d-sm-none" />
					</b-button>
				</div>
			</b-media>
			
			<!-- User Info: Input Fields -->
			<b-form
				@submit.prevent="validationForm"
				@reset.prevent="onReset"
				ref="form"
			>
				<b-row>
					<!-- Field: Given Name -->
					<b-col cols="12" md="4">
						<b-form-group label="Given Name" label-for="givenName">
							<validation-provider
								#default="{ errors }"
								name="Given Name"
								rules="max:30"
							>
								<b-form-input
									id="givenName"
									v-model="form.given_name"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<!-- Field: Family Name -->
					<b-col cols="12" md="4">
						<b-form-group label="Family Name" label-for="familyName">
							<validation-provider
								#default="{ errors }"
								name="Family Name"
								rules="required|max:30"
							>
								<b-form-input
									id="familyName"
									v-model="form.family_name"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<!-- Field: Short Name -->
					<b-col cols="12" md="4">
						<b-form-group label="Short Name" label-for="shortName">
							<validation-provider
								#default="{ errors }"
								name="Short Name"
								rules="required|max:30"
							>
								<b-form-input
									id="shortName"
									v-model="form.short_name"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<!-- Field: Given Name Local -->
					<b-col cols="12" md="4">
						<b-form-group label="Given Name (Local)" label-for="givenNameLocal">
							<validation-provider
								#default="{ errors }"
								name="Given Name (Local)"
								rules="max:30"
							>
								<b-form-input
									id="givenNameLocal"
									v-model="form.given_name_local"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<!-- Field: Family Name Local -->
					<b-col cols="12" md="4">
						<b-form-group
							label="Family Name (Local)"
							label-for="familyNameLocal"
						>
							<validation-provider
								#default="{ errors }"
								name="Family Name (Local)"
								rules="max:30"
							>
								<b-form-input
									id="familyNameLocal"
									v-model="form.family_name_local"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<!-- Field: slug -->
					<b-col cols="12" md="8">
						<b-form-group label="Slug" label-for="slug">
							<validation-provider
								#default="{ errors }"
								name="Slug"
								rules="required"
							>
								<b-form-input
									id="slug"
									v-model="form.slug"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
					<!-- Field: empty -->
					<b-col cols="12" md="4"></b-col>

					<!-- Field: Gender -->
					<b-col cols="12" md="4">
						<b-form-group label="Gender" label-for="gender" class="mb-2">
							<v-select
								id="gender"
								v-model="form.gender"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="genderOptions"
								:reduce="(genderOptions) => genderOptions.value"
							/>
						</b-form-group>
					</b-col>

					<!-- Field: Birth Year -->
					<b-col cols="12" md="4">
						<b-form-group label="Birth Year" label-for="birthYr">
							<b-form-input
								type="number"
								max="9999"
								id="birthYr"
								v-model="form.birth_yr"
								placeholder="YYYY"
							/>
						</b-form-group>
					</b-col>

					<!-- Field: empty -->
					<b-col cols="12" md="4"></b-col>

					<!-- Field: lang_id -->
					<b-col cols="12" md="4">
						<validation-provider
							#default="validationContext"
							name="Language"
							rules="required"
						>
							<b-form-group
								label="Language"
								label-for="lang_ids"
								:state="getValidationState(validationContext)"
							>
								<v-select
									id="lang_ids"
									v-model="userData.availableLang[0].lang.name"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="langOptions"
									:reduce="(langOptions) => langOptions.value"
								/>
								<b-form-invalid-feedback
									:state="getValidationState(validationContext)"
								>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
					</b-col>

					<!-- Field: nid -->
					<b-col cols="12" md="4">
						<b-form-group
							label="Nationality"
							label-for="nid"
						>
							<v-select
								id="nid"
								v-model="form.nid"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="nationalityOptions"
								:reduce="(nationalityOptions) => nationalityOptions.value"
							/>
						</b-form-group>
					</b-col>
					<!-- Field: empty -->
					<b-col cols="12" md="4"></b-col>


					<!-- Field: role_id -->
					<b-col cols="12" md="4">
						<validation-provider
							#default="validationContext"
							name="primary role"
							rules="required"
						>
							<b-form-group
								label="Primary role"
								label-for="roleId"
								:state="getValidationState(validationContext)"
							>
								<v-select
									id="roleId"
									v-model="form.role_id"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="businessRoleOptions"
									:reduce="(businessRoleOptions) => businessRoleOptions.value"
								/>
								<b-form-invalid-feedback
									:state="getValidationState(validationContext)"
								>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
					</b-col>

					<!-- Field: area id -->
					<b-col cols="12" md="4">
						<b-form-group label="Current location" label-for="areaId">
							<v-select
								id="area_id"
								v-model="form.area_id"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="areaOptions"
								:reduce="(areaOptions) => areaOptions.value"
							/>
						</b-form-group>
					</b-col>

					<!-- Industries -->
					<b-col cols="12" md="4">
						<b-form-group label="Primary industry" label-for="industry_id" class="mb-2">
							<v-select
								id="industry_id"
								v-model="form.industry_id"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
								:options="industryOptions"
								:reduce="(industryOptions) => industryOptions.value"
							/>
						</b-form-group>
					</b-col>

					<!-- Field: status -->
					<b-col cols="12" md="4">
						<validation-provider
							#default="validationContext"
							name="Status"
							rules="required"
						>
							<b-form-group
								label="Status"
								label-for="status"
								:state="getValidationState(validationContext)"
							>
								<v-select
									id="status"
									v-model="form.status"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:options="userStatusOptions"
									:reduce="(userStatusOptions) => userStatusOptions.value"
								/>
								<b-form-invalid-feedback
									:state="getValidationState(validationContext)"
								>
									{{ validationContext.errors[0] }}
								</b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
					</b-col>

					<!-- Field: sort -->
					<b-col cols="12" md="4">
						<b-form-group label="Sorting" label-for="sort">
							<validation-provider
								#default="{ errors }"
								name="Slug"
								rules="required"
							>
								<b-form-input
									id="sort"
									type="number"
									v-model="form.sort"
									:state="errors.length > 0 ? false : null"
								/>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					
				</b-row>

				<!-- PERMISSION TABLE -->
				<!--
    <b-card
      no-body
      class="border mt-1"
    >
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permission</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="permissionsData"
      >
        <template #cell(module)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox :checked="data.value" />
        </template>
      </b-table>
    </b-card>
        -->

				<!-- Action Buttons -->
				<b-button
					type="submit"
					variant="primary"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
					:block="$store.getters['app/currentBreakPoint'] === 'xs'"
				>
					Save Changes
				</b-button>
			</b-form>
		</b-card>
	</validation-observer>
</template>

<script>
import {
	BButton,
	BMedia,
	BAvatar,
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BForm,
	BTable,
	BCard,
	BCardHeader,
	BCardTitle,
	BFormCheckbox,
	BFormInvalidFeedback,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { ref, onMounted, onUpdated } from "@vue/composition-api";
import useUsersList from "../users-list/useUsersList";
import router from "@/router";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { max } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
	components: {
		BButton,
		BMedia,
		BAvatar,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BForm,
		BTable,
		BCard,
		BCardHeader,
		BCardTitle,
		BFormCheckbox,
		vSelect,
		BFormInvalidFeedback,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
		fetchUserData: {
			type: Function,
			required: true,
		},
		industryOptions: {
			type: Array,
			required: true,
		},
		businessRoleOptions: {
			type: Array,
			required: true,
		},
		areaOptions: {
			type: Array,
			required: true,
		},
		nationalityOptions: {
			type: Array,
			required: true,
		},
		langOptions: {
			type: Array,
			required: true,
		},
	},
	setup(props) {
		const { resolveUserRoleVariant } = useUsersList();
		const permissionsData = [
			{
				module: "Admin",
				read: true,
				write: false,
				create: false,
				delete: false,
			},
			{
				module: "Staff",
				read: false,
				write: true,
				create: false,
				delete: false,
			},
			{
				module: "Author",
				read: true,
				write: false,
				create: true,
				delete: false,
			},
			{
				module: "Contributor",
				read: false,
				write: false,
				create: false,
				delete: false,
			},
			{
				module: "User",
				read: false,
				write: false,
				create: false,
				delete: true,
			},
		];

		// ? Demo Purpose => Update image on click of update
		const refInputEl = ref(null);
		const previewEl = ref(null);

		// form validation
		const { refFormObserver, getValidationState } = formValidation(() => {});

		return {
			resolveUserRoleVariant,
			avatarText,
			permissionsData,

			// Update Image on click of update button
			refInputEl,
			previewEl,

			// Form Validation
			refFormObserver,
			getValidationState,

			// Form Validators
			required,
			max,
		};
	},
	data() {
		const defaultValue = {
			user_id: this.userData.user_id,
			given_name: this.userData.given_name,
			family_name: this.userData.family_name,
			given_name_local: this.userData.given_name_local,
			family_name_local: this.userData.family_name_local,
			short_name: this.userData.short_name,
			nid: this.userData.nid,
			gender: this.userData.gender,
			birth_yr: this.userData.birth_yr,
			area_id: this.userData.area_id,
			// lang_ids: this.userData.availableLang.map((item) => item.lang_id),
			content: {
				headline: this.userData.content[0].headline,
				about: this.userData.content[0].about,
			},
			area_id: this.userData.area_id,
			role_id: this.userData?.primaryRole?.role_id ?? [],
			industry_id: this.userData?.primaryIndustry?.industry_id,
			slug: this.userData.slug,
			sort: this.userData["sort"],
			nid: this.userData.nid,
			status: this.userData.status,
		};
		const genderOptions = this.$store.getters['app-user/genderOptions'];
		const userStatusOptions = this.$store.getters['app-user/userStatusOptions'];
		return {
			defaultValue,
			form: Object.assign({}, defaultValue),
			genderOptions,
			userStatusOptions
		};
	},
	methods: {
		validationForm(event) {
			this.$refs.refFormObserver.validate().then((success) => {
				if (success) {
					this.$store
						.dispatch("app-user/updateUser", {
							id: router.currentRoute.params.id,
							data: JSON.stringify(this.form),
						})
						.then((response) => {
							console.log(response);
							if (response.status == 200) {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: "The user profile is successfully updated",
										icon: "CheckIcon",
									},
								});
								this.fetchUserData();
							}
						})
						.catch((error) => {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: "Error updating user profile",
									icon: "AlertTriangleIcon",
									variant: "danger",
								},
							});
						});
				}
			});
		},
		onReset(event) {
			Object.assign(this.form, this.defaultValue);
		},
		uploadImage() {
			let formData = new FormData();
			formData.append("user_id", this.userData.user_id);
			formData.append("file", this.$refs.refInputEl.files[0]);
			store
				.dispatch("app-user/updateUserProfilePic", {
					data: formData,
				})
				.then((response) => {
					console.log(response);
					if (response.status == 200) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: "The user profile is successfully updated",
								icon: "CheckIcon",
							},
						});
						this.fetchUserData();
					}
				})
				.catch((error) => {
					console.error(error);
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Error uploading profile image",
							icon: "AlertTriangleIcon",
							variant: "danger",
						},
					});
				});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
