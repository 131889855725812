<template v-if="contacts">
  <validation-observer ref="refFormObserver">
    <div>
      <div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-addContact
          variant="outline-primary"
        >
          Add New Contact
        </b-button>

        <!-- modal add product -->
        <b-modal
          id="modal-addContact"
          ok-title="Add New Contact"
          @ok="addContact"
          centered
          title="Add New Contact"
        >
          <b-form @submit.prevent="addContact" ref="form">
            <b-row>
              <!-- Field: Contact Type -->
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  name="Contact Type"
                  rules="required"
                >
                  <b-form-group
                    label="Contact Type"
                    label-for="type"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="type"
                      v-model="newContactForm.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="contactOptions"
                      :reduce="(contactOptions) => contactOptions.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- Field: Contact Detail -->
              <b-col cols="12">
                <b-form-group label="Contact Detail" label-for="contact">
                  <validation-provider
                    #default="{ errors }"
                    name="Contact Detail"
                    rules="required"
                  >
                    <b-form-input
                      id="contact"
                      v-model="newContactForm.contact"
                      :state="errors.length > 0 ? false : null"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Verified -->
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  name="Verified"
                  rules="required"
                >
                  <b-form-group
                    label="Verified"
                    label-for="verified"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="gender"
                      v-model="newContactForm.verified"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="verifiedOptions"
                      :reduce="(verifiedOptions) => verifiedOptions.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <b-card class="mt-2 pt-75">
        <div v-for="(c, index) in contacts" :key="'pct' + index">
          <!-- User Info: Input Fields -->
          <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
            <b-row align-v="center">
              <template v-if="c.is_primary">
                <!-- Field: Primary Contact Type -->
                <b-col cols="12" md="2">
                  <validation-provider
                    #default="validationContext"
                    name="Primary Contact Type"
                    rules="required"
                  >
                    <b-form-group
                      label="Primary Contact Type"
                      label-for="type"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="type"
                        v-model="c.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="contactOptions"
                        :reduce="(contactOptions) => contactOptions.value"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Primary Contact -->
                <b-col cols="12" md="3">
                  <b-form-group label="Primary Contact" label-for="contact">
                    <validation-provider
                      #default="{ errors }"
                      name="Primary Contact"
                      rules="required"
                    >
                      <b-form-input
                        id="contact"
                        v-model="c.contact"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field: Verified -->
                <b-col cols="12" md="2">
                  <validation-provider
                    #default="validationContext"
                    name="Verified"
                    rules="required"
                  >
                    <b-form-group
                      label="Verified"
                      label-for="verified"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="gender"
                        v-model="c.verified"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="verifiedOptions"
                        :reduce="(verifiedOptions) => verifiedOptions.value"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: status -->
                <b-col cols="12" md="2">
                  <validation-provider
                    #default="validationContext"
                    name="Status"
                    rules="required"
                  >
                    <b-form-group
                      label="status"
                      label-for="status"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="status"
                        v-model="c.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="(statusOptions) => statusOptions.value"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: sort -->
                <b-col cols="12" md="1">
                  <b-form-group label="Sort" label-for="sort">
                    <validation-provider
                      #default="{ errors }"
                      name="Slug"
                      rules="required"
                    >
                      <b-form-input
                        id="sort"
                        type="number"
                        v-model="c.sort"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <template v-else>
                <!-- Field: Secondary Contact Type -->
                <b-col cols="12" md="2">
                  <validation-provider
                    #default="validationContext"
                    name="Other Contact Type"
                    rules="required"
                  >
                    <b-form-group
                      label="Other Contact Type"
                      label-for="type"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="type"
                        v-model="c.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="contactOptions"
                        :reduce="(contactOptions) => contactOptions.value"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: Primary Contact -->
                <b-col cols="12" md="3">
                  <b-form-group label="Other Contact" label-for="contact">
                    <validation-provider
                      #default="{ errors }"
                      name="Other Contact"
                      rules="required"
                    >
                      <b-form-input
                        id="contact"
                        v-model="c.contact"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Field: Verified -->
                <b-col cols="12" md="2">
                  <validation-provider
                    #default="validationContext"
                    name="Verified"
                    rules="required"
                  >
                    <b-form-group
                      label="Verified"
                      label-for="verified"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="gender"
                        v-model="c.verified"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="verifiedOptions"
                        :reduce="(verifiedOptions) => verifiedOptions.value"
                      />
                      <b-form-invalid-feedback
                        :state="getValidationState(validationContext)"
                      >
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <!-- Field: status -->
                <b-col cols="12" md="2">
                  <validation-provider
                    #default="validationContext"
                    name="Status"
                    rules="required"
                  >
                    <b-form-group
                      label="Status"
                      label-for="status"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        id="status"
                        v-model="c.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="(statusOptions) => statusOptions.value"
                      />
                    </b-form-group>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>

                <!-- Field: sort -->
                <b-col cols="12" md="1">
                  <b-form-group label="Sort" label-for="sort">
                    <validation-provider
                      #default="{ errors }"
                      name="Slug"
                      rules="required"
                    >
                      <b-form-input
                        id="sort"
                        type="number"
                        v-model="c.sort"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </template>
              <!-- Action Buttons -->
              <b-col cols="12" md="2">
                <div class="d-flex flex-wrap mt-1">
                  <b-button
                    type="button"
                    variant="primary"
                    @click="updateContact(index)"
                  >
                    <span class="d-sm-inline">Save Changes</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                  <!-- <b-button variant="outline-secondary" class="ml-1" disabled>
                  <span class="d-sm-inline">Remove</span>
                  <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
                </b-button> -->
                </div>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-card>
    </div>
  </validation-observer>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BModal,
  VBModal,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardCode,
    BCardText,
    vSelect,
    BModal,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    fetchUserData: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const verifiedOptions = [
      { label: 'Verified', value: 1 },
      { label: 'Not Verified', value: 0 },
    ]

    const statusOptions = [
      { label: 'Active', value: 1 },
      { label: 'Inactive', value: 0 },
    ]

    const contactOptions = [
      { label: 'Email', value: 'email' },
      { label: 'Mobile', value: 'mobile' },
      { label: 'Wechat', value: 'wechat' },
      { label: 'Line', value: 'line' },
      { label: 'Whatsapp', value: 'whatsapp' },
    ]

    const contacts = ref([])

    props.userData.contact.map((data) => {
      return contacts.value.push({
        user_id: data.user_id,
        type: data.type,
        oldContact: data.contact,
        contact: data.contact,
        is_primary: data.is_primary,
        verified: data.verified,
        status: data.status ? data.status : 1,
        sort: data.sort ? data.sort : 0,
      })
    })

    // form validation
    const { refFormObserver, getValidationState } = formValidation(() => {})

    return {
      verifiedOptions,
      statusOptions,
      contactOptions,

      contacts,

      // Form Validation
      refFormObserver,
      getValidationState,

      // Form Validators
      required,
    }
  },
  data() {
    const newContactDefaultValue = {
      user_id: this.userData.user_id,
      type: [],
      contact: '',
    }
    return {
      contacts: this.contacts,
      defaultContacts: this.contacts,
      newContactForm: Object.assign({}, newContactDefaultValue),
    }
  },
  methods: {
    onSubmit(event) {
      console.log(JSON.stringify(this.contacts))
    },
    updateContact(index) {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.contacts[index].sort = Number.parseInt(this.contacts[index].sort)
          this.$store
            .dispatch('app-user/updateUserContact', {
              data: JSON.stringify(this.contacts[index]),
            })
            .then((response) => {
              console.log(response)
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'The user contact is successfully updated',
                    icon: 'CheckIcon',
                  },
                })
                this.fetchUserData()
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error updating contact',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    addContact(event) {
      event.preventDefault()
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch('app-user/addUserContact', {
              data: JSON.stringify(this.newContactForm),
            })
            .then((response) => {
              console.log(response)
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'The user contact is successfully added',
                    icon: 'CheckIcon',
                  },
                })
                this.fetchUserData()
              }
            })
            .catch((error) => {
              console.log(error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error adding new contact',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onReset(event) {
      Object.assign(this.contacts, defaultContacts)
    },
  },
  watch: {
    userData: function (val) {
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
