<template>
  <component :is="userData === undefined ? 'div' : 'div'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">Error fetching user data</h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <b-card>
      <div class="d-flex flex-wrap">
        <h4 class="mb-0">Edit Profile - {{ userData.display_name }}</h4> 
        <b-button :to="{
                  name: 'apps-users-view',
                  params: { id: userData.user_id },
                }"
          variant="outline-primary" class="ml-2">
          <span class="d-none d-sm-inline">Back to view</span>
        </b-button>
      </div>
    </b-card>

    <b-tabs v-if="userData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <h4 class="mb-1 ml-50">Basic Information</h4>
        <user-edit-tab-account
          :user-data="userData"
          :fetch-user-data="fetchUserData"
          :industryOptions="industryOptions"
          :businessRoleOptions="businessRoleOptions"
          :areaOptions="areaOptions"
          :saleableMarketOptions="saleableMarketOptions"
          :nationalityOptions="nationalityOptions"
          :langOptions="langOptions"
          class="mt-2 pt-75"
        />
        <h4 class="mb-1 ml-50">About User</h4>
        <user-edit-tab-content
          :user-data="userData"
          :fetch-user-data="fetchUserData"
          class="mt-2 pt-75"
        />
        <h4 class="mb-1 ml-50">Contact</h4>
        <user-edit-tab-contact
          :user-data="userData"
          :fetch-user-data="fetchUserData"
          class="mt-1"
        />
        <h4 class="mb-1 ml-50">Media</h4>
        <user-edit-tab-media
          :user-data="userData"
          :fetch-user-data="fetchUserData"
          class="mt-1"
        />
      </b-tab>

      <!-- Tab: Products -->
      <b-tab>
        <template #title>
          <feather-icon icon="PackageIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Products</span>
        </template>
        <user-edit-tab-product
          :user-data="userData"
          :active="userProducts.length > 0 ? true : false"
          :user-products="userProducts"
          :fetch-user-data="fetchUserData"
          :industryOptions="industryOptions"
          :businessRoleOptions="businessRoleOptions"
          :areaOptions="areaOptions"
          :saleableMarketOptions="saleableMarketOptions"
          :modeOptions="modeOptions"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BButton, BTab, BTabs, BCard, BAlert, BLink } from 'bootstrap-vue'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import userStoreModule from '../userStoreModule'
import UserEditTabAccount from './UserEditTabAccount.vue'
import UserEditTabInformation from './UserEditTabInformation.vue'
import UserEditTabSocial from './UserEditTabSocial.vue'
import UserEditTabContent from './UserEditTabContent.vue'
import UserEditTabContact from './UserEditTabContact.vue'
import UserEditTabProduct from './UserEditTabProduct.vue'
import UserEditTabMedia from './UserEditTabMedia.vue'
import useUsersEdit from '../users-edit/useUsersEdit'

export default {
  components: {
    BButton,
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    UserEditTabAccount,
    UserEditTabInformation,
    UserEditTabSocial,
    UserEditTabContent,
    UserEditTabContact,
    UserEditTabProduct,
    UserEditTabMedia,
  },
  setup() {
    const userData = ref(null)
    const userProducts = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    const {
      industryOptions,
      businessRoleOptions,
      areaOptions,
      modeOptions,
      nationalityOptions,
      langOptions,
      saleableMarketOptions,
      fetchIndustryOptions,
      fetchBusinessRoleOptions,
      fetchAreaOptions,
      fetchSalebleMarketOptions,
      fetchModeOptions,
      fetchNationalityOptions,
      fetcLanguageOptions,
    } = useUsersEdit()

    onMounted(async () => {
      fetchIndustryOptions()
      fetchBusinessRoleOptions()
      fetchAreaOptions()
      fetchSalebleMarketOptions()
      fetchModeOptions()
      fetchNationalityOptions()
      fetcLanguageOptions()
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const fetchUserData = () => {
      store
        .dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then((response) => {
          userData.value = response.data.user
          userProducts.value = response.data.products
        })
        .catch((error) => {
          if (error.status === 404) {
            userData.value = undefined
            userProducts.value = undefined
          }
        })
    }

    fetchUserData()

    return {
      userData,
      userProducts,
      fetchUserData,
      industryOptions,
      businessRoleOptions,
      areaOptions,
      saleableMarketOptions,
      modeOptions,
      nationalityOptions,
      langOptions,
    }
  },
}
</script>

<style></style>
