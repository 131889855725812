import store from '@/store'
import { ref, onMounted } from '@vue/composition-api'

const useUserEdit = () => {
  const industryOptions = ref([])
  const businessRoleOptions = ref([])
  const areaOptions = ref([])
  const saleableMarketOptions = ref([])
  const modeOptions = ref([])
  const nationalityOptions = ref([])
  const langOptions = ref([])

  const fetchIndustryOptions = () => {
    if (store.state['app-user'].industryOptions.length == 0) {
      store
        .dispatch('app-user/fetchIndustryList')
        .then((response) => {
          const { industries } = response.data
          const dataList = industries.map((item) => {
            return { label: item.name, value: item.industry_id }
          })
          store.commit('app-user/SET_INDUSTRY_OPTIONS', dataList)
          industryOptions.value = dataList;
        })
        .catch(() => {})
    } else {
      industryOptions.value = store.state['app-user'].industryOptions;
    }
  }

  const fetchBusinessRoleOptions = () => {
    if (store.state['app-user'].businessRoleOptions.length == 0) {
      store
        .dispatch('app-user/fetchBusinessRoleList')
        .then((response) => {
          const { roles } = response.data
          const dataList = roles.map((item) => {
            return { label: item.name, value: item.role_id }
          })
          store.commit('app-user/SET_BUSINESSROLE_OPTIONS', dataList)
          businessRoleOptions.value = dataList;      
        })
        .catch(() => {})
    }
    businessRoleOptions.value = store.state['app-user'].businessRoleOptions;
  }

  const fetchAreaOptions = () => {
    if (store.state['app-user'].areaOptions.length == 0) {
      store
        .dispatch('app-user/fetchAreaList')
        .then((response) => {
          const { areas } = response.data
          const dataList = areas.map((item) => {
            return { label: item.name, value: item.area_id }
          })
          store.commit('app-user/SET_AREA_OPTIONS', dataList)
          areaOptions.value = dataList;      
        })
        .catch(() => {})
    }
    areaOptions.value = store.state['app-user'].areaOptions;
  }

  const fetchSalebleMarketOptions = () => {
    if (store.state['app-user'].saleableMarketOptions.length == 0) {
      store
        .dispatch('app-user/fetchSalebleMarket')
        .then((response) => {
          const { markets } = response.data
           const dataList = markets.map((item) => {
            return { label: item.name, value: item.market_id }
          })
          store.commit('app-user/SET_SALEABLEMARKET_OPTIONS', dataList)
          saleableMarketOptions.value = dataList;      
        })
        .catch(() => {})
    }
    saleableMarketOptions.value = store.state['app-user'].saleableMarketOptions;
  }

  const fetchModeOptions = () => {
    if (store.state['app-user'].modeOptions.length == 0) {
      store
        .dispatch('app-user/fetchMode')
        .then((response) => {
          const { modes } = response.data
          const dataList = modes.map((item) => {
            return { label: item.name, value: item.mode_id }
          })
          store.commit('app-user/SET_MODE_OPTIONS', dataList)
          modeOptions.value = dataList;      
        })
        .catch(() => {})
    }
    modeOptions.value = store.state['app-user'].modeOptions;
  }

  const fetchNationalityOptions = () => {
    if (store.state['app-user'].modeOptions.length == 0) {
      store
        .dispatch('app-user/fetchNationality')
        .then((response) => {
          const { nationalities } = response.data
          const dataList = nationalities.map((item) => {
            return { label: item.name, value: item.nid }
          })
          store.commit('app-user/SET_NATIONALITY_OPTIONS', dataList)
          nationalityOptions.value = dataList;      
        })
        .catch(() => {})
    }
    nationalityOptions.value = store.state['app-user'].modeOptions;
  }

  const fetcLanguageOptions = () => {
    if (store.state['app-user'].langOptions.length == 0) {
      store
        .dispatch('app-user/fetchLanguage')
        .then((response) => {
          const { langs } = response.data
          const dataList = langs.map((item) => {
            return { label: item.name, value: item.lang_id }
          })
          store.commit('app-user/SET_LANG_OPTIONS', dataList)
          langOptions.value = dataList;      
        })
        .catch(() => {})
    }
    langOptions.value = store.state['app-user'].langOptions;
  }

  return {
    industryOptions,
    businessRoleOptions,
    areaOptions,
    saleableMarketOptions,
    modeOptions,
    nationalityOptions,
    langOptions,
    fetchIndustryOptions,
    fetchBusinessRoleOptions,
    fetchAreaOptions,
    fetchSalebleMarketOptions,
    fetchModeOptions,
    fetchNationalityOptions,
    fetcLanguageOptions,
  }
}

export default useUserEdit
