<template>
	<validation-observer ref="refFormObserver">
		<b-card>
			<!-- User Info: Input Fields -->
			<b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
				<b-row>
					<!-- Content Headline -->
					<b-col cols="12" md="12" class="mb-2">
						<h6 class="mb-sm">1-line Description</h6>
						<validation-provider
							#default="{ errors }"
							name="Headline"
							rules="max:100"
						>
							<quill-editor
								ref="headline"
								class="editor"
								v-model="form.content.headline"
								:options="headlineEditorOption"
							>
								<div id="htoolbar" slot="toolbar">
									<!-- Add a bold button -->
									<button class="ql-bold">Bold</button>
									<button class="ql-italic">Italic</button>
									<!-- Add font size dropdown -->
									<select class="ql-size">
										<option value="small"></option>
										<!-- Note a missing, thus falsy value, is used to reset to default -->
										<option selected></option>
										<option value="large"></option>
										<option value="huge"></option>
									</select>
								</div>
							</quill-editor>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-col>
				</b-row>

				<b-row>
					<!-- Content Headline -->
					<b-col cols="12" md="12" class="mb-2">
						<h6 class="mb-sm">About</h6>
						<validation-provider
							#default="{ errors }"
							name="About"
							rules="max:5000"
						>
							<quill-editor
								ref="content"
								class="editor"
								v-model="form.content.about"
								:options="contentEditorOption"
							>
								<div id="atoolbar" slot="toolbar">
									<!-- Add a bold button -->
									<button class="ql-bold">Bold</button>
									<button class="ql-italic">Italic</button>
									<!-- Add font size dropdown -->
									<select class="ql-size">
										<option value="small"></option>
										<!-- Note a missing, thus falsy value, is used to reset to default -->
										<option selected></option>
										<option value="large"></option>
										<option value="huge"></option>
									</select>
								</div>
							</quill-editor>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-col>
				</b-row>

				<!-- Action Buttons -->
				<b-button
					type="submit"
					variant="primary"
					class="mb-1 mb-sm-0 mr-0 mr-sm-1"
				>
					Save Changes
				</b-button>
			</b-form>
		</b-card>
	</validation-observer>
</template>

<script>
import { ref } from "@vue/composition-api";
import {
	BButton,
	BMedia,
	BAvatar,
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BForm,
	BTable,
	BCard,
	BCardHeader,
	BCardTitle,
	BFormCheckbox,
	BFormInvalidFeedback,
} from "bootstrap-vue";
import { BCardText } from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { quillEditor } from "vue-quill-editor";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
	components: {
		BButton,
		BMedia,
		BAvatar,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BForm,
		BTable,
		BCard,
		BCardHeader,
		BCardTitle,
		BFormCheckbox,
		quillEditor,
		BCardCode,
		BCardText,
		BFormInvalidFeedback,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
	},
	props: {
		userData: {
			type: Object,
			required: true,
		},
		fetchUserData: {
			type: Function,
			required: true,
		},
	},
	setup() {
		const headline = ref(null);
		const content = ref(null);

		// form validation
		const { refFormObserver, getValidationState } = formValidation(() => {});

		return {
			headline,
			content,

			// Form Validation
			refFormObserver,
			getValidationState,

			// Form Validators
			required,
			max,
		};
	},
	data() {
		const defaultValue = {
			user_id: this.userData.user_id,
			content: {
				headline: this.userData.content[0].headline,
				about: this.userData.content[0].about,
			},
		};

		return {
			headlineEditorOption: {
				modules: {
					toolbar: "#htoolbar",
				},
			},
			contentEditorOption: {
				modules: {
					toolbar: "#atoolbar",
				},
			},
			form: Object.assign({}, defaultValue),
		};
	},
	methods: {
		onSubmit(event) {
			console.log(JSON.stringify(this.form));
			this.$refs.refFormObserver.validate().then((success) => {
				if (success) {
					this.$store
						.dispatch("app-user/updateUser", {
							data: JSON.stringify(this.form),
						})
						.then((response) => {
							console.log(response);
							if (response.status == 200) {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: "The user description is successfully updated",
										icon: "CheckIcon",
									},
								});
								this.fetchUserData();
							}
						})
						.catch((error) => {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: "Error updating user description",
									icon: "AlertTriangleIcon",
									variant: "danger",
								},
							});
						});
				}
			});
		},
		onReset(event) {
			this.$refs.headline.setHTML("");
			this.$refs.content.setHTML("");
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
