<template v-if="medias">
  <validation-observer ref="refFormObserver">
    <div>
      <div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-addMedia
          variant="outline-primary"
        >
          Add New Media
        </b-button>

        <!-- modal add product -->
        <b-modal
          id="modal-addMedia"
          ok-title="Add New Media"
          @ok="addMedia"
          centered
          title="Add New Media"
        >
          <b-form @submit.prevent="addMedia" ref="form">
            <b-row>
              <!-- Field: Media Type -->
              <b-col cols="12">
                <validation-provider
                  #default="validationContext"
                  name="Media Type"
                  rules="required"
                >
                  <b-form-group
                    label="Media Type"
                    label-for="type"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="type"
                      v-model="newMediaForm.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="mediaTypeOptions"
                      :reduce="(mediaTypeOptions) => mediaTypeOptions.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col v-if="newMediaForm.type == 'photo'" cols="12">
                <b-form-group label="Upload" label-for="newUpload">
                  <validation-provider
                    #default="{ errors }"
                    name="Upload"
                    rules="required"
                  >
                    <b-form-file
                      id="newUpload"
                      ref="refNewUploadFile"
                      v-model="newMediaForm.mediapath"
                      :state="Boolean(newMediaForm.mediapath)"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col v-if="newMediaForm.type == 'youtube'" cols="12">
                <b-form-group label="Video Link" label-for="videoLink">
                  <validation-provider
                    #default="{ errors }"
                    name="Video Link"
                    rules="required"
                  >
                    <b-row>
                      <b-col sm="7" class="col-form-label">
                        <label for="input-small">https://www.youtube.com/watch?v=</label>
                      </b-col>
                      <b-col>
                        <b-form-input
                          id="videoLink"
                          v-model="newMediaForm.mediapath"
                          :state="errors.length > 0 ? false : null"
                          required
                        />
                      </b-col>
                    </b-row>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Field: Caption -->
              <b-col cols="12">
                <b-form-group label="Caption" label-for="caption">
                  <validation-provider
                    #default="{ errors }"
                    name="Caption"
                    rules="required"
                  >
                    <b-form-input
                      id="caption"
                      v-model="newMediaForm.caption"
                      :state="errors.length > 0 ? false : null"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form>
        </b-modal>
      </div>
      <b-card class="mt-2 pt-75">
        <div v-for="(m, index) in medias" :key="'media' + index" class="mb-2">
          <!-- User Info: Input Fields -->
          <b-form @submit.prevent="onSubmit" @reset.prevent="onReset">
            <b-row align-v="center">
              <b-col v-if="m.type == 'photo'" cols="12" md="2">
                <b-avatar
                  :ref="'previewMedia' + index"
                  :src="m.mediapath"
                  :variant="`light`"
                  button @click="triggerEnlargeImage(index)"
                  rounded
                  class="w-100 h-100"
                />
              </b-col>

              <b-modal v-if="m.type == 'photo'" :id="'imageViewer' + index" size="xl" okOnly body-class="text-center">
                <b-img :src='m.mediapath' fluid class="vh-80"/>
              </b-modal>

              <b-col v-if="m.type == 'youtube'" cols="12" md="2">
                <b-avatar
                  :ref="'previewMedia' + index"
                  :src="'https://img.youtube.com/vi/' + m.mediapath + '/0.jpg'"
                  :href="'https://www.youtube.com/watch?v=' + m.mediapath"
                  target="_blank"
                  :variant="`light`"
                  rounded
                  class="w-100 h-100"
                />
              </b-col>

              <b-col cols="12" md="2">
                <validation-provider
                  #default="validationContext"
                  name="Media"
                  rules="required"
                >
                  <b-form-group
                    label="Media Type"
                    label-for="type"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      id="type"
                      v-model="m.type"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="mediaTypeOptions"
                      :reduce="(mediaTypeOptions) => mediaTypeOptions.value"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="4">
                <b-form-group label="Caption" label-for="caption">
                  <validation-provider
                    #default="{ errors }"
                    name="Caption"
                    rules="required"
                  >
                    <b-form-input
                      id="caption"
                      v-model="m.caption"
                      :state="errors.length > 0 ? false : null"
                      required
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- Action Buttons -->
              <b-col cols="12" md="2">
                <div class="d-flex flex-wrap mt-1">
                  <b-button
                    type="button"
                    variant="primary"
                    @click="updateMedia(index)"
                  >
                    <span class="d-sm-inline">Save Changes</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                  <!-- <b-button variant="outline-secondary" class="ml-1" disabled>
                  <span class="d-sm-inline">Remove</span>
                  <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
                </b-button> -->
                </div>
              </b-col>

              <b-col cols="12" md="2">
                <div class="d-flex flex-wrap mt-1">
                  <b-button
                    type="button"
                    variant="danger"
                    @click="removeMedia(index)"
                  >
                    <span class="d-sm-inline">Delete</span>
                    <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
                  </b-button>
                  <!-- <b-button variant="outline-secondary" class="ml-1" disabled>
                  <span class="d-sm-inline">Remove</span>
                  <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
                </b-button> -->
                </div>
              </b-col>
            </b-row>
          </b-form>
        </div>
      </b-card>
    </div>
  </validation-observer>
</template>

<script>
import { ref } from '@vue/composition-api'
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormFile,
  BForm,
  BImg,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BModal,
  VBModal,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { BCardText } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import vSelect from 'vue-select'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormFile,
    BForm,
    BImg,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardCode,
    BCardText,
    vSelect,
    BModal,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    fetchUserData: {
      type: Function,
      required: true,
    },
  },
  setup(props) {

    const mediaTypeOptions = [
      { label: 'Photo', value: 'photo' },
      { label: 'YouTube', value: 'youtube' },
    ]

    const medias = ref([])

    if(props.userData.media) {
      props.userData.media.map((data) => {
        return medias.value.push({
          mediapath: data.mediapath,
          type: data.type,
          caption: data.caption
        })
      })
    }

    // form validation
    const { refFormObserver, getValidationState } = formValidation(() => {})

    return {
      mediaTypeOptions,

      medias,

      // Form Validation
      refFormObserver,
      getValidationState,

      // Form Validators
      required,
    }
  },
  data() {
    const newMediaDefaultValue = {
      mediapath: null,
      type: '',
      caption: '',
    }
    return {
      medias: this.medias,
      defaultMedias: this.medias,
      newMediaForm: Object.assign({}, newMediaDefaultValue),
    }
  },
  methods: {
    triggerEnlargeImage(index) {
      this.$bvModal.show('imageViewer'+index)
    },
    onSubmit(event) {
      console.log(JSON.stringify(this.medias))
    },
    updateMedia(index) {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.$store
            .dispatch('app-user/updateUserMedia', {
              data: {
                      user_id: this.userData.user_id,
                      media: this.medias
                    },
            })
            .then((response) => {
              console.log(response)
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'The user media is successfully updated',
                    icon: 'CheckIcon',
                  },
                })
                this.fetchUserData()
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error updating contact',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    addMedia(event) {
      event.preventDefault()
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          if (this.newMediaForm.type == 'photo') {
            this.medias.push({
              type: 'photo',
              mediapath: this.newMediaForm.mediapath,
              caption: this.newMediaForm.caption
            })
            let formData = new FormData();
            formData.append("user_id", this.userData.user_id);
            formData.append("file", this.$refs.refNewUploadFile.files[0]);
            formData.append("caption", this.newMediaForm.caption);

            this.$store
              .dispatch('app-user/addUserMedia', {
                data: formData,
              })
              .then((response) => {
                console.log(response)
                if (response.status == 200) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'The media is successfully added',
                      icon: 'CheckIcon',
                    },
                  })
                  this.fetchUserData()
                  location.reload()
                }
              })
              .catch((error) => {
                console.log(error)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Error adding new media',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })
          } else {
            this.medias.push({
              type: 'youtube',
              mediapath: this.newMediaForm.mediapath,
              caption: this.newMediaForm.caption
            })
            this.$store
            .dispatch('app-user/addUserMediaLink', {
              data: {
                      user_id: this.userData.user_id,
                      media: this.medias
                    },
            })
            .then((response) => {
              console.log(response)
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'The user media is successfully updated',
                    icon: 'CheckIcon',
                  },
                })
                this.fetchUserData()
                location.reload()
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error updating contact',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          }
        }
      })
    },
    removeMedia(index) {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.medias.splice(index, 1)
          this.$store
            .dispatch('app-user/updateUserMedia', {
              data: {
                      user_id: this.userData.user_id,
                      media: this.medias
                    },
            })
            .then((response) => {
              console.log(response)
              if (response.status == 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'The user media is successfully updated',
                    icon: 'CheckIcon',
                  },
                })
                this.fetchUserData()
              }
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error updating contact',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        }
      })
    },
    onReset(event) {
      Object.assign(this.medias, defaultMedias)
    },
  },
  watch: {
    userData: function (val) {
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>
