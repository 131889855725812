<template>
	<validation-observer ref="refFormObserver">
		<div>
			<!-- Header: Product Info -->
			<div class="d-flex mb-1">
				<h4 class="mb-0 ml-50">Products</h4>
			</div>
			<div v-if="active == true" class="mt-2 pt-75">
				<b-row>
					<b-col cols="12">
						<!-- Basic -->
						<b-card no-body>
							<b-card-body>
								<b-row>
									<!-- Left: Product Image Container -->
									<b-col cols="12" md="5" class="mb-2 mb-md-0">
										<div>
											<b-img
												:src="
													userProducts[0].media.length > 0
														? userProducts[0].media[0].media_path
														: require(`@/assets/images/pages/default-product.png`)
												"
												:alt="`Image of ${
													userProducts[0].media.length
														? userProducts[0].media[0].name
														: 'default product'
												}`"
												class="w-100 product-img"
											/>
										</div>
										<div class="mt-1">
											<b-button
												variant="primary"
												@click="$refs.refInputEl.click()"
											>
												<input
													ref="refInputEl"
													type="file"
													class="d-none"
													@input="uploadImage"
												/>
												<span class="d-none d-sm-inline">Upload</span>
												<feather-icon
													icon="EditIcon"
													class="d-inline d-sm-none"
												/>
											</b-button>
										</div>
									</b-col>

									<!-- Right: Product Detail -->
									<b-col cols="12" md="7">
										<!-- User Info: Input Fields -->
										<b-form @submit.prevent="onSubmit" ref="form">
											<b-row>
												<!-- Field: Display Status -->
												<b-col cols="12">
													<validation-provider
														#default="validationContext"
														name="Display Status"
														rules="required"
													>
														<b-form-group
															label="Display Status"
															label-for="displayStatus"
															:state="getValidationState(validationContext)"
														>
															<v-select
																id="displayStatus"
																v-model="form.display_status"
																:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
																:options="displayStatusOptions"
																:reduce="(displayStatusOptions) => displayStatusOptions.value"
															/>
															<b-form-invalid-feedback
																:state="getValidationState(validationContext)"
															>
																{{ validationContext.errors[0] }}
															</b-form-invalid-feedback>
														</b-form-group>
													</validation-provider>
												</b-col>
												<!-- Field: Product Name -->
												<b-col cols="12">
													<b-form-group
														label="Product Name"
														label-for="productName"
													>
														<validation-provider
															#default="{ errors }"
															name="Product Name"
															rules="required|max:50"
														>
															<b-form-input
																id="productName"
																:state="errors.length > 0 ? false : null"
																v-model="form.content.name"
															/>
															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-form-group>
												</b-col>

												<!-- Field: Description -->
												<b-col cols="12">
													<b-form-group
														label="Description"
														label-for="description"
													>
														<validation-provider
															#default="{ errors }"
															name="Description"
															rules="required|max:100"
														>
															<b-form-input
																id="description"
																v-model="form.content.description"
																:state="errors.length > 0 ? false : null"
															/>
															<small class="text-danger">{{ errors[0] }}</small>
														</validation-provider>
													</b-form-group>
												</b-col>

												<!-- Field: Specification -->
												<b-col cols="12" class="mb-2">
													<p class="text-normal mb-0">Specification</p>
													<validation-provider
														#default="{ errors }"
														name="Specification"
														rules="max:5000"
													>
														<quill-editor
															ref="spec"
															class="editor"
															v-model:content="form.content.spec"
															:options="specEditorOption"
														>
															<div id="stoolbar" slot="toolbar">
																<!-- Add a bold button -->
																<button class="ql-bold">Bold</button>
																<button class="ql-italic">Italic</button>
																<!-- Add font size dropdown -->
																<select class="ql-size">
																	<option value="small"></option>
																	<!-- Note a missing, thus falsy value, is used to reset to default -->
																	<option selected></option>
																	<option value="large"></option>
																	<option value="huge"></option>
																</select>
															</div>
														</quill-editor>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-col>

												<!-- Field: About -->
												<b-col cols="12" class="mb-2">
													<p class="text-normal mb-0">More About the product</p>
													<validation-provider
														#default="{ errors }"
														name="About"
														rules="max:5000"
													>
														<quill-editor
															ref="about"
															class="editor"
															v-model:content="form.content.about"
															:options="aboutEditorOption"
														>
															<div id="patoolbar" slot="toolbar">
																<!-- Add a bold button -->
																<button class="ql-bold">Bold</button>
																<button class="ql-italic">Italic</button>
																<!-- Add font size dropdown -->
																<select class="ql-size">
																	<option value="small"></option>
																	<!-- Note a missing, thus falsy value, is used to reset to default -->
																	<option selected></option>
																	<option value="large"></option>
																	<option value="huge"></option>
																</select>
															</div>
														</quill-editor>
														<small class="text-danger">{{ errors[0] }}</small>
													</validation-provider>
												</b-col>

												<!-- Field: Who would use this -->
												<b-col cols="12" class="mb-2">
													<p class="text-normal mb-0">Who would use this</p>
													<quill-editor
														ref="spec"
														class="editor"
														v-model:content="form.content.benefit_for_user"
														:options="benefitForUserEditorOption"
													>
														<div id="bfutoolbar" slot="toolbar">
															<!-- Add a bold button -->
															<button class="ql-bold">Bold</button>
															<button class="ql-italic">Italic</button>
															<!-- Add font size dropdown -->
															<select class="ql-size">
																<option value="small"></option>
																<!-- Note a missing, thus falsy value, is used to reset to default -->
																<option selected></option>
																<option value="large"></option>
																<option value="huge"></option>
															</select>
														</div>
													</quill-editor>
												</b-col>

												<!-- Field: Who would trade this -->
												<b-col cols="12" class="mb-2">
													<p class="text-normal mb-0">Who would trade this</p>
													<quill-editor
														ref="spec"
														class="editor"
														v-model:content="form.content.benefit_for_partner"
														:options="benefitForTraderEditorOption"
													>
														<div id="bfttoolbar" slot="toolbar">
															<!-- Add a bold button -->
															<button class="ql-bold">Bold</button>
															<button class="ql-italic">Italic</button>
															<!-- Add font size dropdown -->
															<select class="ql-size">
																<option value="small"></option>
																<!-- Note a missing, thus falsy value, is used to reset to default -->
																<option selected></option>
																<option value="large"></option>
																<option value="huge"></option>
															</select>
														</div>
													</quill-editor>
												</b-col>

												<!-- Industries -->
												<b-col cols="12">
													<validation-provider
														#default="validationContext"
														name="Industries"
														rules="required"
													>
														<b-form-group
															label="Industries"
															label-for="industry_id"
															class="mb-2"
															:state="getValidationState(validationContext)"
														>
															<v-select
																id="industry_id"
																v-model="form.industry"
																:dir="
																	$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
																"
																:options="industryOptions"
																multiple
																:reduce="
																	(industryOptions) => industryOptions.value
																"
															/>
															<b-form-invalid-feedback
																:state="getValidationState(validationContext)"
															>
																{{ validationContext.errors[0] }}
															</b-form-invalid-feedback>
														</b-form-group>
													</validation-provider>
												</b-col>

												<!-- primary Market -->
												<b-col cols="12" md="6">
													<validation-provider
														#default="validationContext"
														name="Primary Market"
														rules="required"
													>
														<b-form-group
															label="Primary Market"
															label-for="primaryMarket"
															class="mb-2"
															:state="getValidationState(validationContext)"
														>
															<v-select
																id="primaryMarket"
																v-model="form.primary_market"
																:dir="
																	$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
																"
																:options="saleableMarketOptions"
																:reduce="
																	(saleableMarketOptions) =>
																		saleableMarketOptions.value
																"
															/>
															<b-form-invalid-feedback
																:state="getValidationState(validationContext)"
															>
																{{ validationContext.errors[0] }}
															</b-form-invalid-feedback>
														</b-form-group>
													</validation-provider>
												</b-col>

												<!-- Saleable Market -->
												<b-col cols="12" md="6">
													<validation-provider
														#default="validationContext"
														name="Saleable Market"
														rules="required"
													>
														<b-form-group
															label="Saleable Market"
															label-for="saleableMarket"
															class="mb-2"
															:state="getValidationState(validationContext)"
														>
															<v-select
																id="saleableMarket"
																v-model="form.saleable_market"
																:dir="
																	$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
																"
																:options="saleableMarketOptions"
																:reduce="
																	(saleableMarketOptions) =>
																		saleableMarketOptions.value
																"
															/>
															<b-form-invalid-feedback
																:state="getValidationState(validationContext)"
															>
																{{ validationContext.errors[0] }}
															</b-form-invalid-feedback>
														</b-form-group>
													</validation-provider>
												</b-col>

												<!-- Preferred Mode -->
												<b-col cols="12" md="6">
													<validation-provider
														#default="validationContext"
														name="Preferred Mode"
														rules="required"
													>
														<b-form-group
															label="Preferred Mode"
															label-for="modPreferred"
															class="mb-2"
															:state="getValidationState(validationContext)"
														>
															<v-select
																id="modPreferred"
																v-model="form.mode_preferred"
																:dir="
																	$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
																"
																:options="modeOptions"
																:reduce="(modeOptions) => modeOptions.value"
															/>
															<b-form-invalid-feedback
																:state="getValidationState(validationContext)"
															>
																{{ validationContext.errors[0] }}
															</b-form-invalid-feedback>
														</b-form-group>
													</validation-provider>
												</b-col>

												<!-- Accepted Mode -->
												<b-col cols="12" md="6">
													<validation-provider
														#default="validationContext"
														name="Accepted Mode"
														rules="required"
													>
														<b-form-group
															label="Accepted Mode"
															label-for="modAccepted"
															class="mb-2"
															:state="getValidationState(validationContext)"
														>
															<v-select
																id="modPreferred"
																v-model="form.mode_accepted"
																:dir="
																	$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
																"
																:options="modeOptions"
																multiple
																:reduce="(modeOptions) => modeOptions.value"
															/>
															<b-form-invalid-feedback
																:state="getValidationState(validationContext)"
															>
																{{ validationContext.errors[0] }}
															</b-form-invalid-feedback>
														</b-form-group>
													</validation-provider>
												</b-col>
											</b-row>

											<!-- Action Buttons -->
											<b-button
												type="submit"
												variant="primary"
												class="mb-1 mb-sm-0 mr-0 mr-sm-1"
												:block="
													$store.getters['app/currentBreakPoint'] === 'xs'
												"
											>
												Save Changes
											</b-button>
										</b-form>
									</b-col>
								</b-row>
							</b-card-body>
						</b-card>
					</b-col>
				</b-row>

				<!-- Fallback -->
				<b-row>
					<b-col cols="12" md="3">
						<div v-if="!userProducts.length">-</div>
					</b-col>
				</b-row>
			</div>
			<div v-else>
				<b-button
					v-ripple.400="'rgba(113, 102, 240, 0.15)'"
					v-b-modal.modal-addProduct
					variant="outline-primary"
				>
					Add New Product
				</b-button>

				<!-- modal add product -->
				<b-modal
					id="modal-addProduct"
					ok-title="Add Product"
					@ok="addProduct"
					centered
					title="Add Product"
				>
					<b-form @submit.prevent="addProduct" ref="form">
						<b-row>
							<!-- Field: Product Name -->
							<b-col cols="12">
								<b-form-group label="Product Name" label-for="productName">
									<validation-provider
										#default="{ errors }"
										name="Product Name"
										rules="required|max:50"
									>
										<b-form-input
											id="productName"
											v-model="form.newProductName"
											:state="errors.length > 0 ? false : null"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
						</b-row>
					</b-form>
				</b-modal>
			</div>
		</div>
	</validation-observer>
</template>

<script>
import {
	BButton,
	BMedia,
	BAvatar,
	BRow,
	BCol,
	BFormGroup,
	BFormInput,
	BForm,
	BTable,
	BCard,
	BCardHeader,
	BCardTitle,
	BCardBody,
	BFormCheckbox,
	BModal,
	VBModal,
	BImg,
	BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { quillEditor } from "vue-quill-editor";
import router from "@/router";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, max } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

export default {
	components: {
		BButton,
		BMedia,
		BAvatar,
		BRow,
		BCol,
		BFormGroup,
		BFormInput,
		BForm,
		BTable,
		BCard,
		BCardHeader,
		BCardTitle,
		BCardBody,
		BFormCheckbox,
		vSelect,
		BModal,
		BImg,
		quillEditor,
		BFormInvalidFeedback,

		// Form Validation
		ValidationProvider,
		ValidationObserver,
	},
	directives: {
		"b-modal": VBModal,
		Ripple,
	},
	props: {
		active: {
			type: Boolean,
		},
		userData: {
			type: Object,
			required: true,
		},
		userProducts: {
			type: Array,
			required: true,
		},
		fetchUserData: {
			type: Function,
			required: true,
		},
		industryOptions: {
			type: Array,
			required: true,
		},
		businessRoleOptions: {
			type: Array,
			required: true,
		},
		areaOptions: {
			type: Array,
			required: true,
		},
		saleableMarketOptions: {
			type: Array,
			required: true,
		},
		modeOptions: {
			type: Array,
			required: true,
		},
	},
	setup(props) {
		const statusOptions = [
			{ label: "Active", value: 1 },
			{ label: "Inactive", value: 0 },
		];

		const refInputEl = ref(null);
		const spec = ref(null);
		const about = ref(null);
		const benefitForUser = ref(null);
		const benefitForTrader = ref(null);

		// form validation
		const { refFormObserver, getValidationState } = formValidation(() => {});

		return {
			statusOptions,
			refInputEl,
			spec,
			about,
			benefitForUser,
			benefitForTrader,

			// Form Validation
			refFormObserver,
			getValidationState,

			// Form Validators
			required,
			max,
		};
	},
	data() {
		let defaultValue, displayStatusOptions;
		if (this.userProducts.length > 0) {
			defaultValue = {
				user_id: this.userProducts[0].user_id,
				product_id: this.userProducts[0].product_id,
				display_status: this.userProducts[0].display_status,
				industry: this.userProducts[0].industry,
				mode_accepted: this.userProducts[0].mode_accepted,
				mode_preferred: this.userProducts[0].mode_preferred,
				primary_market: this.userProducts[0].primary_market,
				saleable_market: this.userProducts[0].saleable_market,
				content: {
					name: this.userProducts[0]
						? this.userProducts[0].content[0].name
						: "",
					description: this.userProducts[0].content[0].description,
					about: this.userProducts[0].content[0].about,
					spec: this.userProducts[0].content[0].spec,
					benefit_for_user: this.userProducts[0].content[0].benefit_for_user,
					benefit_for_partner:
						this.userProducts[0].content[0].benefit_for_partner,
				},
				avail_platform: {
					alibaba: this.userProducts[0].avail_platform
						? this.userProducts[0].avail_platform.alibaba
						: "",
					amazon: this.userProducts[0].avail_platform
						? this.userProducts[0].avail_platform.amazon
						: "",
				},
			};
			displayStatusOptions = this.$store.getters['app-user/displayStatusOptions'];
		} else {
			defaultValue = {
				user_id: this.userData.user_id,
				newProductName: "",
			};
		}

		return {
			defaultValue,
			form: Object.assign({}, defaultValue),
			specEditorOption: {
				modules: {
					toolbar: "#stoolbar",
				},
			},
			aboutEditorOption: {
				modules: {
					toolbar: "#patoolbar",
				},
			},
			benefitForUserEditorOption: {
				modules: {
					toolbar: "#bfutoolbar",
				},
			},
			benefitForTraderEditorOption: {
				modules: {
					toolbar: "#bfttoolbar",
				},
			},
			displayStatusOptions
		};
	},
	methods: {
		onSubmit(event) {
			this.$refs.refFormObserver.validate().then((success) => {
				if (success) {
					this.$store
						.dispatch("app-user/updateUserProduct", {
							id: router.currentRoute.params.id,
							data: JSON.stringify(this.form),
						})
						.then((response) => {
							console.log(response);
							if (response.status == 200) {
								this.$toast({
									component: ToastificationContent,
									props: {
										title: "The user profile is successfully updated",
										icon: "CheckIcon",
									},
								});
								this.fetchUserData();
							}
						})
						.catch((error) => {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: "Error updating user product",
									icon: "AlertTriangleIcon",
									variant: "danger",
								},
							});
						});
				}
			});
		},
		addProduct(event) {
			this.$store
				.dispatch("app-user/addUserProduct", {
					data: JSON.stringify({
						user_id: this.userData.user_id,
						display_status: 0,
						content: [
							{
								lang_id: 1,
								name: "test",
							},
						],
					}),
				})
				.then((response) => {
					console.log(response);
					if (response.status == 200) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: "The user profile is successfully updated",
								icon: "CheckIcon",
							},
						});
						this.fetchUserData();
					}
				})
				.catch((error) => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Error adding new contact",
							icon: "AlertTriangleIcon",
							variant: "danger",
						},
					});
				});
		},
		uploadImage() {
			let formData = new FormData();
			formData.append("product_id", this.userProducts[0].product_id);
			formData.append("file", this.$refs.refInputEl.files[0]);
			store
				.dispatch("app-user/updateUserProductPic", {
					data: formData,
				})
				.then((response) => {
					console.log(response);
					if (response.status == 200) {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: "The user product is successfully updated",
								icon: "CheckIcon",
							},
						});
						this.fetchUserData();
					}
				})
				.catch((error) => {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: "Error updating product image",
							icon: "AlertTriangleIcon",
							variant: "danger",
						},
					});
				});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
